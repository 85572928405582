// import firebase from 'firebase/app';
import { notification } from 'antd';
// import 'firebase/auth';
// import 'firebase/database';
// import 'firebase/storage';

import { BACKEND_URL } from '../components/data/constants';
// import AuthContext from '../context/auth-context';

// const firebaseConfig = {
//   apiKey: 'AIzaSyAE5G0RI2LwzwTBizhJbnRKIKbiXQIA1dY',
//   authDomain: 'cleanui-72a42.firebaseapp.com',
//   databaseURL: 'https://cleanui-72a42.firebaseio.com',
//   projectId: 'cleanui-72a42',
//   storageBucket: 'cleanui-72a42.appspot.com',
//   messagingSenderId: '583382839121'
// };

// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const firebaseAuth = firebase.auth;
// export default firebaseApp;

// export async function login(email, password) {
//   return firebaseAuth()
//     .signInWithEmailAndPassword(email, password)
//     .then(() => true)
//     .catch(error => {
//       notification.warning({
//         message: error.code,
//         description: error.message
//       });
//     });
// }

// export async function currentAccount() {
//   let userLoaded = false;
//   function getCurrentUser(auth) {
//     return new Promise((resolve, reject) => {
//       if (userLoaded) {
//         resolve(firebaseAuth.currentUser);
//       }
//       const unsubscribe = auth.onAuthStateChanged(user => {
//         userLoaded = true;
//         unsubscribe();
//         resolve(user);
//       }, reject);
//     });
//   }
//   return getCurrentUser(firebaseAuth());
// }

// export async function logout() {
//   return firebaseAuth()
//     .signOut()
//     .then(() => true);
// }

// const samlUrl = 'https://backend.dev.fleetforum.org'
export async function samlLogin() {
  try {
    const loginReq = await fetch(`${BACKEND_URL}/login`);
    // console.log('loginurl', loginReq)
    if ((await loginReq.status) !== 200 && (await loginReq.status) !== 201) {
      throw new Error('SAML Login Failed');
    }
    const loginRes = await loginReq.json();
    // console.log(await loginRes)
    window.location.href = loginRes;
  } catch (error) {
    notification.error({
      message: error.code,
      description: error.message
    });
  }
}

export async function samlLogout() {
  try {
    window.location.href = `${BACKEND_URL}/logout`;
    // const logoutReq = await fetch(`${BACKEND_URL}/logout`)
    // if ((await logoutReq.status) !== 200 && (await logoutReq.status) !== 201) {
    //   throw new Error('SAML logout failed')
    // }
    // const logoutRes = await logoutReq.json()
    // console.log(logoutRes)
    // if (logoutRes.status === 200) window.location.reload()
    // window.location.href = await logoutReq.json()
  } catch (error) {
    console.log(error.message);
    // notification.error({
    //   message: error.code,
    //   description: error.message,
    // })
  }
}

export async function authStatusCheck() {
  try {
    const statusReq = await fetch(`${BACKEND_URL}/getUserDetails`, {
      credentials: 'include'
    });
    const statusRes = await statusReq.json();
    return statusRes;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getUserDetails() {
  try {
    const userReq = await fetch(`${BACKEND_URL}/getUserDetails`, {
      credentials: 'include'
    });
    const userRes = await userReq.json();
    if (userRes.status === 'loggedOut') {
      // clear user details in local storage
    }

    if ((await userReq.status) !== 200 && (await userReq.status) !== 201) {
      throw new Error('Cannot get user details');
    }
    const userDetails = await userReq.json();
    // console.log(userDetails)
    if (userDetails.status === 302 || userDetails.status === 'login')
      return null;
    // const userDetails = await userReq.json()
    // console.log(userDetails)
    return userDetails;
  } catch (error) {
    console.log(error);
    return notification.error({
      message: error.code,
      description: error.message
    });
  }
}
