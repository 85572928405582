/* eslint no-underscore-dangle: 0 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createHashHistory } from 'history';
import Router from './router';
import reducers from './redux/reducers';
import sagas from './redux/sagas';

import Localization from './components/LayoutComponents/Localization';
// import * as serviceWorker from './serviceWorker'
import { saveState, loadState } from './redux/tco/localStorage/functions';
// import TcoContext from './context/tco-context'

// app styles
import './global.scss';

const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
// if (process.env.NODE_ENV === 'development' && true) {
//   middlewares.push(logger);
// }

const persistedState = loadState('tcoState');
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers(history),
  persistedState,
  composeEnhancers(applyMiddleware(...middlewares))
);
sagaMiddleware.run(sagas);

store.subscribe(() => {
  saveState('tcoState', store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root')
);

// serviceWorker.register()
// Check for browser support of service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./serviceWorker.js')
    .then(registration => {
      // Successful registration
      console.log('Service Worker scope:', registration.scope);
    })
    .catch(err => {
      // Failed registration, service worker won’t be installed
      console.log('Whoops. Service worker registration failed, error:', err);
    });
}
export { store, history };
