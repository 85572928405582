import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';
// import ChangeColor from '../../TCOcomponents/ExportData/ChangeColor';

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className="row">
        <div className="col-lg-9">
          <strong>
            <FormattedMessage id="common.siteTitle" />
          </strong>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className="row">
          <div className="col-md-8">
            <p>
              <FormattedMessage id="common.footer.text" />
            </p>
          </div>
          <div className="col-md-4">
            <div className={styles.copyright}>
              <img
                src="resources/images/tco/FleetForumLogo-1.png"
                rel="noopener noreferrer"
                alt="Fleet Forum"
              />
              <span>
                ©{' '}
                <a
                  href="https://www.fleetforum.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Fleet Forum Association
                </a>
                <br />
                <FormattedMessage id="common.footer.rights" />
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-2 text-secondary">
            <p className="mb-0">
              <strong>
                <FormattedMessage id="common.footer.disclaimerTitle" />
              </strong>
            </p>
            <p>
              <FormattedMessage id="common.footer.disclaimerText" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
