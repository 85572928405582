import { ConfigProvider } from 'antd';
import localeData from 'react-intl/locale-data/fr';

const messages = {
  // Shared text
  'topBar.home': `Accueil`,
  'topBar.langEn': `English`,
  'topBar.langFr': `Français`,
  'topBar.langEs': `Español`,
  'common.siteTitle': `Toolkit sur le coût total de possession (TCO)`,
  'common.kmFull': `Kilomètre`,
  'common.mileFull': `Mile`,
  'common.perKm': `par km`,
  'common.perMil': `par mile`,
  'common.perDay': `par jour`,
  'common.day': `Jour`,
  'common.days': `Jours`,
  'common.year': `Année`,
  'common.years': `Années`,
  'common.perMonthAbbr': `PM`,
  'common.perYearAbbr': `an`,
  'common.vehicle': `Véhicule`,
  'common.costPerKm': `Coûts par Kilomètre`,
  'common.costPerMil': `Coûts par Mile`,
  'common.costPerDay': `Coût par Jour`,
  'common.export.title': `Rapports d’exportation`,
  'common.export.pdf': `PDF (tableaux + données)`,
  'common.export.excel': `Excel (données seules)`,
  'common.export.image': `Image (tableaux seuls)`,
  'common.conclusion.title': `Conclusion`,
  'common.conclusion.placeholder': `S'affichera après le calcul`,
  'common.conclusion.text': `Si vous utilisiez un {cheaperVehicle} au lieu d'un {expVehicle}, vous économiseriez {distSavings} par {distanceUnit} parcourue (ou {annualSavings} par an) sur une période de {period} ans.`,
  'common.footer.text': `Cet outil vous aide à calculer et à comparer les coûts d'achat d'un véhicule et de location. Il utilise vos données pour déterminer les dépenses encourues dans les deux cas. Il calcule ensuite les coûts par jour, par année et par kilomètre.`,
  'common.footer.rights': `Tous droits réservés`,
  'common.footer.disclaimerTitle': `Clause de non-responsabilité`,
  'common.footer.disclaimerText': `Cet outil a pour but de fournir des conseils sur les coûts de votre flotte, ce qui n'est qu'un aspect de la gestion de la flotte. Fleet Forum n'est pas responsable des conséquences des décisions que vous prenez en utilisant le calculateur TCO. Vous êtes donc seul responsable de la confiance que vous accordez aux informations obtenues grâce au calculateur de TCO.`,
  'common.report.ready': `Votre Rapport est prêt à être téléchargé`,
  'common.report.cancel': `Annuler`,
  'common.report.download': `Télécharger le Rapport`,
  'charts.totalDailyCost': `Coût total journalier`,
  'charts.totalAnnualCost': `Coût total annuel`,

  // Charts
  'charts.vehicle': `Véhicule`,
  'charts.vehicleA': `Véhicule 1`,
  'charts.vehicleB': `Véhicule 2`,
  'charts.owning': `Posséder`,
  'charts.renting': `Louer`,
  'charts.fuel': `Carburant`,
  'charts.tyres': `Pneus`,
  'charts.depreciation': `Dépréciation`,
  'charts.maintenance': `Maintenance`,
  'charts.insurance': `Assurance`,
  'charts.labor': `Main d’oeuvre`,
  'charts.admin': `Admin`,
  'charts.breakdown': `Composition`,
  'charts.repair': `Réparations`,
  'charts.roadTax': `Taxe routière`,
  'charts.other': `Autres`,
  'charts.rental': `Location`,
  'charts.vehSelReport': `Rapport sur la sélection des véhicules`,
  'charts.owningA': `Posséder un`,
  'charts.rentingA': `Louer un`,
  'charts.costPer': `Coût par`,
  'charts.plan': `Plan`,
  'charts.barDailyTitle': `Comparaison des coûts journaliers des véhicules`,
  'topBar.profileMenu.guest': `Invité`,
  'topBar.profileMenu.hello': `Bonjour`,
  'topBar.profileMenu.name': `Nom`,
  'topBar.profileMenu.role': `Rôle`,
  'topBar.profileMenu.email': `Email`,
  'topBar.profileMenu.organisation': `Organisation`,
  'topBar.profileMenu.viewProfile': `Voir Profil`,
  'topBar.profileMenu.login': `Connexion`,
  'topBar.profileMenu.logout': `Déconnexion`,

  // Forms
  'forms.gen.title': `Unités standards`,
  'forms.gen.calcUsd': `Calculer en USD`,
  'forms.gen.savedDs': `Ensembles de données sauvegardés`,
  'forms.gen.optSelectDs': `--Choisir Ensemble de données--`,
  'forms.gen.load': `Charger`,
  'forms.gen.dsName': `Nom de l’ensemble de données`,
  'forms.gen.dsName.info': `Nom de votre ensemble de données`,
  'forms.gen.dsName.placeholder': `Mon premier ensemble de données`,
  'forms.gen.saveDs': `Sauvegarder`,
  'forms.gen.updateDs': `Mettre à jour`,
  'forms.gen.lblCountry': `Choisissez un pays`,
  'forms.gen.optSelectCountry': `--Choisir Pays--`,
  'forms.gen.lblSelCurrency': `Choisissez la devise`,
  'forms.gen.optSelectCurrency': `--Choisir Devise--`,
  'forms.gen.localReg': `Région locale`,
  'forms.gen.localReg.info': `La ville/région de ce pays à laquelle l'ensemble de données se rapporte`,
  'forms.gen.localReg.placeholder': `Ma Région`,
  'forms.gen.lblDsUnits': `Unités de distance`,
  'forms.gen.lblFuelUnits': `Unités de carburant`,
  'forms.gen.lblCurrency': `Devise`,
  'forms.gen.currency.info': `Devise à utiliser dans ce calcul`,

  'forms.workingDays': `Jours ouvrables`,
  'forms.workingDays.info': `Le nombre de jours ouvrables dans une année. La norme est fixée à 250 jours, sur la base d'une semaine de travail de 5 jours et de 10 jours fériés. La valeur peut être ajustée.`,
  'forms.dailyMil': `Kilométrage journalier`,
  'forms.dailyMil.info': `Distance moyenne parcourue par jour`,
  'forms.vehType': `Type de véhicule`,
  'forms.optSelVehType': `--Choisir le type de véhicule--`,
  'forms.fuelType': `Type de carburant / énergie`,
  'forms.optSelFuelType': `--Choisir type de carburant / énergie--`,
  'forms.vehDescription': `Description du véhicule`,
  'forms.vehDescription.info': `Saisissez quelques informations sur le véhicule, par exemple la marque, le modèle, l'année, la cylindrée, le type de carburant, etc.`,
  'forms.fuelPrice': `Prix du carburant / énergie`,
  'forms.fuelPrice.info': `Coût par unité de carburant / énergie pour ce véhicule`,
  'forms.fuelConsumption': `Consommation de carburant / énergie`,
  'forms.fuelConsumption.info': `Consommation moyenne de carburant pour ce véhicule`,
  'forms.tyreMil': `Kilométrage des pneus`,
  'forms.tyreMil.info': `Kilométrage moyen entre deux changements de pneus`,
  'forms.lblAdmin': `Frais administratifs mensuels`,
  'forms.admin.info': `Coûts liés à la gestion du véhicule. Vous pouvez inclure le salaire du gestionnaire du parc automobile, les frais encourus pour la gestion du parc automobile ou les frais de suivi du véhicule, etc. Si vous n'avez pas de gestionnaire de flotte attitré, veuillez prendre un pourcentage du salaire du membre du personnel en charge de la flotte.`,
  'forms.labor': `Coûts salariaux mensuels`,
  'forms.labor.info': `Coût mensuel de la main-d'œuvre du véhicule, par exemple le salaire et les indemnités journalières du conducteur`,
  'forms.depreciation': `Période d'amortissement`,
  'forms.depreciation.info': `Nombre d'années pendant lesquelles le véhicule est utilisé (et amorti) avant d'être mis au rebut. La période d'amortissement est souvent décrite dans la police d'assurance transport ou véhicule.`,
  'forms.acqValue': `Valeur d'acquisition`,
  'forms.acqValue.info': `Coût de la mise en service de ce véhicule. Comprend également le coût du transport entre le fournisseur et le lieu d'exploitation, par exemple les frais d'importation, les frais d'immatriculation, etc.`,
  'forms.maintenance': `Coût de la maintenance`,
  'forms.maintenance.info': `Le coût de l'entretien préventif et programmé par an`,
  'forms.insurance': `Coût annuel de l'assurance`,
  'forms.insurance.info': `Coût des primes d'assurance par an`,
  'forms.tyres': `Coût des pneus`,
  'forms.tyres.info': `Coût moyen d'un jeu de pneus pour ce véhicule`,
  'forms.repairs': `Coûts de réparation`,
  'forms.repairs.info': `Le coût des réparations imprévues et non programmées par an`,
  'forms.numBreakdowns': `Nombre de pannes`,
  'forms.numBreakdowns.info': `Le nombre de fois où le véhicule tombe en panne de manière inattendue. Par exemple, en raison d'un pneu crevé, d'un obstacle sur le terrain ou d'un incident sur le véhicule.`,
  'forms.breakdownCost': `Coût moyen de la panne`,
  'forms.breakdownCost.info': `le coût de la récupération du véhicule depuis le lieu de la panne jusqu'à la base. Cela inclut les coûts des services de remorquage ou si un autre véhicule doit être envoyé pour récupérer le véhicule en panne.`,
  'forms.taxes': `Taxes routières annuelles`,
  'forms.taxes.info': `Taxe périodique sur les véhicules à moteur circulant sur la voie publique`,
  'forms.disposalMeth': `Méthode d'élimination`,
  'forms.optDisposalMeth': `Vente / Enchères`,
  'forms.disposalVal': `Valeur de cession`,
  'forms.disposalVal.info': `Le montant que vous obtiendriez en vendant / mettant aux enchères ce véhicule après sa période d'amortissement. Indiquez la valeur nette (prix de vente - frais encourus pour vendre le véhicule). Si l'organisation fait don du véhicule, cette valeur est nulle.`,
  'forms.calc': `Calculer`,
  'forms.reset': `Réinitialiser le formulaire`,

  // Vehicle Selection Specific
  'topBar.veh.title': `Sélection du véhicule`,
  'forms.veh.title': `Données du véhicule`,
  'forms.veh.two': `Comparer deux`,
  'forms.veh.one': `Estimer une`,
  'forms.veh.both': `Les deux`,
  'forms.veh.a': `Véhicule 1`,
  'forms.veh.b': `Véhicule 2`,
  'forms.veh.both.heading': `Données identiques pour les deux types de véhicules`,
  'veh.report.title': `Rapport de sélection du véhicule`,

  // OvR specific
  'topBar.ovr.title': `Posséder ou louer un véhicule`,
  'forms.ovr.own': `Possession`,
  'forms.ovr.rent': `Location`,
  'forms.ovr.dailyRental': `Prix de location journalier`,
  'forms.ovr.dailyRental.info': `Montant à payer par jour pour la location de ce véhicule`,
  'forms.ovr.selective': `Sélectif`,
  'forms.ovr.allInc': `Tout compris`,
  'forms.ovr.priceIncl': `Price Inclusive`,
  'forms.ovr.separate': `Coût séparé`,
  'forms.ovr.rentalFuelInc': `Coût du carburant inclus`,
  'forms.ovr.rentalFuelSep': `Coût du carburant séparé`,
  'forms.ovr.fuelCost': `Coût total du carburant`,
  'forms.ovr.fuelCost.info': `Coût total du plein du véhicule. Indiquez le coût du carburant s'il n'est pas inclus dans les frais de location du véhicule, sinon indiquez qu'il est inclus.`,
  'ovr.conclusion.text': `à confirmer`,

  // Notifications, errors and warnings
  'notification.info.genReport': `Génération du rapport, veuillez patienter…`,
  'notification.info.calUsd': `LE PANNEAU DES RÉSULTATS A ÉTÉ MIS À JOUR ! Ceci est temporaire et reviendra à la devise sélectionnée lorsque vous recalculerez ou naviguerez hors de cette page.`,
  'notification.error.invalidCurrency': `Devise non valide sélectionnée`,
  'notification.error.calcError': `Veuillez remplir toutes les données requises, puis cliquez sur le bouton "calculer".`,
  'notification.error.invalidExp': `Format d'exportation non valide`,
  'notification.info.updating': `Chargement...`,
  'notification.info.saving': `Sauvegarde...`,
  'notification.info.wait': `Veuillez patienter...`,
  'notification.warn.title': `Attention !`,
  'notification.warn.interrupted': `Processus interrompu`,
  'notification.warn.interrupted.info': `Le processus a été interrompu, veuillez patienter un instant et réessayer.`,
  'notification.warn.loadDs': `Veuillez sélectionner et charger l'ensemble de données que vous souhaitez mettre à jour, ou en enregistrer un nouveau.`,
  'notification.error.title': `Erreur !`,
  'notification.error.saving': `Une erreur s'est produite lors de l'enregistrement de vos données`,
  'notification.error.updating': `Une erreur s'est produite lors de la mise à jour de vos données`,
  'notification.error.saving.vehA': `Une erreur s'est produite lors de la sauvegarde des données du véhicule 1`,
  'notification.error.updating.vehA': `Une erreur s'est produite lors de la mise à jour des données du véhicule 1`,
  'notification.error.saving.vehB': `Une erreur s'est produite lors de l'enregistrement des données du véhicule 2`,
  'notification.error.updating.vehB': `Une erreur s'est produite lors de la mise à jour des données du véhicule 2`,
  'notification.info.success': `Succès !`,
  'notification.info.successSaving': `Vos données ont été sauvegardées avec succès`,
  'notification.info.successUpdating': `Vos données ont été mises à jour avec succès`,
  'notification.warn.login': `Veuillez d'abord vous connecter`,
  'notification.error.loadDs': `Veuillez sélectionner un jeu de données à charger !`,
  'notification.warn.fixSave': `Veuillez corriger les problèmes mis en évidence dans les unités standard avant de les enregistrer.`,
  'notification.warn.fixUpd': `Veuillez corriger les problèmes mis en évidence dans les unités standard avant de procéder à la mise à jour.`,
  'notification.warn.fixCalc': `Veuillez corriger les problèmes mis en évidence dans les unités standard avant le calcul.`,
  'notification.warn.fixCalc2': `Veuillez corriger les problèmes mis en évidence avant de procéder au calcul.`,
  'notification.warn.fixCalcUsd': `veuillez corriger les problèmes mis en évidence dans la section Unités standard avant la conversion en USD`,
  'notification.info.loadDs': `Charger l'ensemble de données ?`,
  'notification.info.loadDs.confirm': `Le chargement de l'ensemble de données écrasera les données actuellement saisies dans le formulaire. Voulez-vous continuer ?`,
  'notification.error.invalidDs': `Ensemble de données non valide sélectionné !`,
  'notification.error.fixData.save': `Veuillez corriger les problèmes mis en évidence dans la section des données du véhicule avant de sauvegarder.`,
  'notification.error.fixData.update': `Veuillez corriger les problèmes mis en évidence dans la section des données sur les véhicules avant de procéder à la mise à jour.`,

  // Form errors
  'forms.err.dsName': `Veuillez donner un nom à votre ensemble de données`,
  'forms.err.selCountry': `Veuillez sélectionner un pays`,
  'forms.err.required': `Requis`,
  'forms.warn.fix': `veuillez résoudre les problèmes mis en évidence avant de poursuivre`,
  'forms.err.zero': `la valeur ne peut être nulle`,
  'forms.err.vehType': `Veuillez sélectionner un type de véhicule`,
  'forms.err.fuelType': `Veuillez sélectionner un type de carburant`,
  'forms.err.vehDescription': `Veuillez saisir une description du véhicule`,

  // Extras
  'extras.country': `Les pays`,
  'extras.created': `Créé le`,
  'extras.modified': `Dernière modification`,
  'extras.delete': `Supprimer`,
  'extras.actions': `Actions`,
  'extras.datasets': `Bases de données`,
  'extras.ovr': `Posséder ou louer`,
  'extras.profile': `Profil`,
  'extras.bought': `acheté`,
  'extras.buying': `acheter`,
  'extras.rented': `loué`,
  'extras.renting': `louer`,
  'extras.ownSummary': `Si vous aviez {cheaperOpt} un/e {vehicleDescription} au lieu d'en {expOption} un/e, vous auriez économisé {currency} {annualSavings} par an (ou {currency} {distanceSavings}) par {distanceUnit} parcourus) sur une période de {period} ans.`,
  'extras.optSelDistUnit': `-- Select Distance Unit--`,
  'extras.optSelFuelUnit': `-- Select Fuel Unit--`,
  'extras.optSelDispMeth': `-- Select Disposal Method--`
};

export default {
  locale: `fr-FR`,
  ConfigProvider,
  localeData,
  messages
};
