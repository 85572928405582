import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';

import Loader from './components/LayoutComponents/Loader';
import IndexLayout from './layouts';
import NotFoundPage from './pages/404';

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />
  });

const routes = [
  // TCO Pages
  {
    path: '/vehicle-selection',
    component: loadable(() =>
      import('./components/TCOcomponents/VehicleSelection')
    ),
    exact: true
  },
  {
    path: '/own-vs-rent',
    component: loadable(() => import('./components/TCOcomponents/OwnVsRent')),
    exact: true
  },
  {
    path: '/profile',
    component: loadable(() => import('./pages/profile')),
    exact: true
  }
];

class Router extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/vehicle-selection" />}
            />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    );
  }
}

export default Router;
