// import * as actionTypes from './actions';

const initialState = {
  workingDays: null,
  dailyMileage: null,
  tyreMileage: null,
  adminCost: null,
  laborCost: null,
  depreciationPeriod: 5,
  activeTab: 'common',
  veh_showDailyStats: false,
  vehFormNoErrors: false,
  compare: true,

  typeA_id: '',
  typeA_vehicleType: '',
  typeA_vehicleTypeId: '',
  typeA_fuelType: '',
  typeA_fuelTypeId: '',
  typeA_vehicleDescription: null,
  typeA_fuelPrice: null,
  typeA_consumption: null,
  typeA_tyreCost: null,
  typeA_maintenance: null,
  typeA_insuranceCost: null,
  typeA_repairCost: null,
  typeA_breakdowns: null,
  typeA_breakdowncost: null,
  typeA_roadTax: null,
  typeA_acquisitionValue: null,
  typeA_disposalValue: null,
  typeA_disposalMethod: 'auction',
  typeA_disposalMethodId: '',

  typeB_id: '',
  typeB_vehicleType: '',
  typeB_vehicleTypeId: '',
  typeB_fuelType: '',
  typeB_fuelTypeId: '',
  typeB_vehicleDescription: null,
  typeB_fuelPrice: null,
  typeB_consumption: null,
  typeB_tyreCost: null,
  typeB_maintenance: null,
  typeB_insuranceCost: null,
  typeB_repairCost: null,
  typeB_breakdowns: null,
  typeB_breakdowncost: null,
  typeB_roadTax: null,
  typeB_acquisitionValue: null,
  typeB_disposalValue: null,
  typeB_disposalMethod: 'auction',
  typeB_disposalMethodId: '',
}

const reducer = (state = initialState, action) => {
  const newState = { ...state }
  // console.log("action: ", action);
  if (action.type === 'reset') {
    return initialState
  }

  Object.keys(initialState).forEach(function eachKey(key) {
    if (key === action.type) {
      newState[key] = action.payload
      state = newState
    }
    // return state;
  })
  return state
}

export default reducer
