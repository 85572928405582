import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduce } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

import LanguageSwitcher from './LanguageSwitcher';
import ProfileMenu from './ProfileMenu';

const mapStateToProps = ({ menu }) => ({
  isMenuTop: menu.isMenuTop,
  menuTopData: menu.menuTopData,
  menuLeftData: menu.menuLeftData
});

class Breadcrumbs extends React.Component {
  state = {
    breadcrumb: []
  };

  componentDidMount() {
    this.setBreadcrumbs(this.props);
  }

  setBreadcrumbs = props => {
    const { isMenuTop, menuTopData, menuLeftData } = this.props;
    const location = this.props.location.pathname;
    // console.log('Location:', this.props.location.pathname);

    this.setState({
      breadcrumb: this.getBreadcrumb(
        props,
        isMenuTop || location === '/profile' ? menuTopData : menuLeftData
      )
    });
  };

  getPath(data, url, parents = []) {
    const items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result;
        }
        if (entry.url === url) {
          return [entry].concat(parents);
        }
        if (entry.children) {
          const nested = this.getPath(
            entry.children,
            url,
            [entry].concat(parents)
          );
          return (result || []).concat(nested.filter(e => !!e));
        }
        return result;
      },
      []
    );
    return items.length > 0 ? items : [false];
  }

  getBreadcrumb = (props, items) => {
    const [activeMenuItem, ...path] = this.getPath(
      items,
      props.location.pathname
    );

    // console.log(activeMenuItem);
    // console.log(activeMenuItem);

    if (activeMenuItem && path.length) {
      return path.reverse().map((item, index) => {
        if (index === path.length - 1) {
          return (
            <span key={item.key}>
              <span className={`${styles.arrow} text-muted`} />
              <span className="text-muted">{item.title}</span>
              <span className={styles.arrow} />
              <strong>{activeMenuItem.title}</strong>
            </span>
          );
        }
        return (
          <span key={item.key}>
            <span className={`${styles.arrow} text-muted`} />
            <span className="text-muted">
              <FormattedMessage id={activeMenuItem.i8nKey} />
            </span>
          </span>
        );
      });
    }
    return (
      <span>
        <span className={styles.arrow} />
        <strong>
          <FormattedMessage id={activeMenuItem.i8nKey || 'extras.profile'} />
        </strong>
      </span>
    );
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setBreadcrumbs(newProps);
  }

  render() {
    const { breadcrumb } = this.state;
    return (
      <div className={styles.breadcrumbs}>
        <div className={styles.path}>
          <Link to="/" className="text-muted">
            <FormattedMessage id="topBar.home" />
          </Link>
          {breadcrumb}
        </div>
        <div className="ml-auto mr-3 pt-4 d-none d-sm-block">
          <LanguageSwitcher />
        </div>
        <ProfileMenu />
      </div>
    );
  }
}

// export default Breadcrumbs;
export default withRouter(connect(mapStateToProps)(Breadcrumbs));
