// import stringTranslations from '../locales/stringTranslations'
export async function getLeftMenuData() {
  return [
    {
      title: 'Vehicle Selection',
      key: 'vehicleSelection',
      url: '/vehicle-selection',
      icon: 'ivycon-vehicleselection',
      i8nKey: 'topBar.veh.title'
    },
    {
      title: 'Owning VS Renting a Vehicle',
      key: 'ownVsRent',
      url: '/own-vs-rent',
      icon: 'ivycon-ownvsrentvehicle',
      i8nKey: 'topBar.ovr.title'
    }
    // {
    //   title: "Member's Profile",
    //   key: 'profile',
    //   url: '/profile',
    //   icon: 'fa fa-user',
    // },
  ];
}
export async function getTopMenuData() {
  return [
    {
      title: 'Vehicle Selection',
      key: 'vehicleSelection',
      url: '/vehicle-selection',
      icon: 'ivycon-vehicleselection',
      i8nKey: 'topBar.veh.title'
    },
    {
      title: 'Owning VS Renting a Vehicle',
      key: 'ownVsRent',
      url: '/own-vs-rent',
      icon: 'ivycon-ownvsrentvehicle',
      i8nKey: 'topBar.ovr.title'
    },
    {
      title: "Member's Profile",
      key: 'profile',
      url: '/profile',
      icon: 'fa fa-user',
      i8nKey: 'extras.profile'
    }
    // {
    //   title: 'Settings',
    //   key: 'settings',
    //   icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    // },
  ];
}
