// import * as actionTypes from './actions';

// const loadedState =
const initialState = {
  ovr_defaultTab: 'both',
  ovr_showDailyStats: true,
  ovr_FormNoErrors: false,
  ovr_id: '',

  ovr_vehicleType: '',
  ovr_vehicleTypeId: '',
  ovr_fuelType: '',
  ovr_fuelTypeId: '',
  ovr_vehicleDescription: 'Vehicle',
  ovr_workingDays: null,
  ovr_fuelPrice: null,
  ovr_dailyMileage: null,
  ovr_fuelConsumption: null,
  ovr_dailyToll: null,

  ovr_acquisitionValue: null,
  ovr_tyreMileage: null,
  ovr_tyreCost: null,
  ovr_numBreakdowns: null,
  ovr_breakdownCost: null,
  ovr_repairCost: null,
  ovr_laborCost: null,
  ovr_adminCost: null,
  ovr_insuranceCost: null,
  ovr_annualMaintenanceCost: null,
  ovr_roadTaxes: null,
  ovr_depreciationPeriod: 5,
  ovr_disposalMethod: 'auction',
  ovr_disposalMethodId: '',
  ovr_disposalValue: null,

  ovr_rentalPrice: null,
  ovr_rentalMaintenanceCost: null,
  ovr_rentalRepairCost: null,
  ovr_rentalLaborCost: null,
  ovr_rentalAdminCost: null,
  // ovr_rentalFuelCost: null,
  ovr_rentalAllInclusive: false,
  ovr_rentalMaintenanceCostSeparate: true,
  ovr_rentalRepairCostSeparate: true,
  ovr_rentalLaborCostSeparate: true,
  ovr_rentalAdminCostSeparate: true,
  ovr_rentalFuelCostSeparate: false
  // saveState: null,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  if (action.type === 'reset') {
    return initialState;
  }

  const checkIfAllInclusive = () => {
    if (
      newState.ovr_rentalMaintenanceCostSeparate === false &&
      newState.ovr_rentalRepairCostSeparate === false &&
      newState.ovr_rentalLaborCostSeparate === false &&
      newState.ovr_rentalAdminCostSeparate === false
      // newState.ovr_rentalFuelCostSeparate === false
    ) {
      return true;
    }
    return false;
  };

  // console.log(action.payload);

  Object.keys(initialState).forEach(function eachKey(key) {
    if (key === action.type) {
      newState[key] = action.payload;
      if (
        action.type === 'ovr_rentalMaintenanceCostSeparate' ||
        action.type === 'ovr_rentalRepairCostSeparate' ||
        action.type === 'ovr_rentalLaborCostSeparate' ||
        action.type === 'ovr_rentalAdminCostSeparate' ||
        action.type === 'ovr_rentalFuelCostSeparate'
      ) {
        newState.ovr_rentalAllInclusive = checkIfAllInclusive();
      }
      if (action.type === 'ovr_rentalAllInclusive') {
        newState.ovr_rentalMaintenanceCostSeparate = !action.payload;
        newState.ovr_rentalRepairCostSeparate = !action.payload;
        newState.ovr_rentalLaborCostSeparate = !action.payload;
        newState.ovr_rentalAdminCostSeparate = !action.payload;
        newState.ovr_rentalFuelCostSeparate = !action.payload;
      }
      state = newState;
    }
  });
  return state;
};

export default reducer;
