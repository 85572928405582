import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import generalReducer from './tco/generalReducers';
import vehSelectReducer from './tco/vehicleSelection/reducers';
import ownVsRentReducer from './tco/ownVsRent/reducers';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    generalReducer,
    vehSelectReducer,    
    ownVsRentReducer
  })
