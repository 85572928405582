import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import { Helmet } from 'react-helmet';
import Loader from '../components/LayoutComponents/Loader';
import PublicLayout from './Public';
import MainLayout from './Main';

const Layouts = {
  public: PublicLayout,
  main: MainLayout
};

const mapStateToProps = ({ user }) => ({ user });
class IndexLayout extends React.PureComponent {
  previousPath = '';

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { prevLocation } = prevProps;
    if (location !== prevLocation) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user
    } = this.props;

    // NProgress Management
    const currentPath = pathname + search;
    if (currentPath !== this.previousPath) {
      NProgress.start();
    }

    setTimeout(() => {
      NProgress.done();
      this.previousPath = currentPath;
    }, 300);

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public';
      }
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return 'login';
      }
      return 'main';
    };

    const Container = Layouts[getLayout()];
    const isUserLoading = user.loading;

    const BootstrappedLayout = () => {
      // show loader
      if (isUserLoading) {
        return <Loader />;
      }
      return <Container>{children}</Container>;
    };

    return (
      <Fragment>
        <Helmet
          titleTemplate="Total Cost of Ownership (TCO) Tool | %s"
          title="Fleet Forum Association"
        />
        {BootstrappedLayout()}
      </Fragment>
    );
  }
}

// export default IndexLayout;
export default withRouter(connect(mapStateToProps)(IndexLayout));
