import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, Avatar, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

const mapStateToProps = ({ user, settings }) => ({
  // theme: settings.theme,
  mode: settings.menuColor,
  user
});

const ProfileMenu = ({ dispatch, user, mode }) => {
  const logout = () => {
    dispatch({
      type: 'user/SAML_LOGOUT'
    });
  };

  const login = () => {
    dispatch({
      type: 'user/SAML_LOGIN'
    });
  };

  const guestAvatar =
    // eslint-disable-next-line no-nested-ternary
    !user.authorized && mode === 'dark'
      ? 'resources/images/avatars/avatar.png'
      : !user.authorized && mode === 'white'
      ? 'resources/images/avatars/avatar-2.png'
      : 'resources/images/avatars/avatar.png';

  const items = [];
  const topRow = {
    key: 'top',
    label: (
      <>
        <strong>
          {/* {trans('hello')}, {user.name || trans('anon')} */}
          <FormattedMessage id="topBar.profileMenu.name" />:{' '}
          {user.name || 'Guest'}
        </strong>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.role}
        </div>
      </>
    )
  };
  const orgRow = {
    key: 'org',
    label: (
      <>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.organisation" />:
          </strong>{' '}
          {user.organisation}
        </div>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:
          </strong>{' '}
          {user.email}
        </div>
      </>
    )
  };
  const dividerRow = {
    key: 'div',
    label: <Divider dashed className="my-0" />
  };
  const dividerRow2 = {
    key: 'div2',
    label: <Divider dashed className="my-0" />
  };
  const profileRow = {
    key: 'profile',
    label: (
      <Link to="/profile">
        <i className={`${styles.menuIcon} icmn-user`} />
        <FormattedMessage id="topBar.profileMenu.viewProfile" />
      </Link>
    )
  };
  const logoutRow = {
    key: 'logout',
    label: (
      <a
        href=""
        onClick={e => {
          e.preventDefault();
          logout(e);
          this.props.history.push('/');
        }}
      >
        <i className={`${styles.menuIcon} icmn-exit`} />
        <FormattedMessage id="topBar.profileMenu.logout" />
      </a>
    )
  };
  const loginRow = {
    key: 'login',
    label: (
      <a
        href=""
        onClick={e => {
          e.preventDefault();
          login(e);
        }}
      >
        <i className={`${styles.menuIcon} icmn-enter`} />
        <FormattedMessage id="topBar.profileMenu.login" />
      </a>
    )
  };

  // construct the array
  items.push(topRow);
  if (user?.authorized) items.push(orgRow);
  items.push(dividerRow);
  if (user?.authorized) {
    items.push(profileRow);
    items.push(dividerRow2);
    items.push(logoutRow);
  } else items.push(loginRow);

  return (
    <Dropdown menu={{ items }}>
      <div className={styles.dropdown}>
        <div className={styles.flag}>
          <Avatar
            className={styles.avatar}
            shape="square"
            size="large"
            src={<img src={user.avatar || guestAvatar} alt="pic" />}
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(ProfileMenu);

// import React from 'react';
// import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
// import { Menu, Dropdown, Avatar, Divider } from 'antd';
// import { UserOutlined } from '@ant-design/icons';
// import { FormattedMessage } from 'react-intl';
// import styles from './style.module.scss';

// // eslint-disable-next-line
// const MenuItemGroup = Menu.ItemGroup;
// // const SubMenu = Menu.SubMenu

// const mapStateToProps = ({ user }) => ({ user });

// class ProfileMenu extends React.Component {
//   state = {
//     // isAuth: false,
//     user: {}
//   };

//   logoutHandler = () => {
//     const { dispatch } = this.props;
//     dispatch({
//       type: 'user/SAML_LOGOUT'
//     });
//   };

//   loginHandler = () => {
//     const { dispatch } = this.props;
//     dispatch({
//       type: 'user/SAML_LOGIN'
//     });
//   };

//   // eslint-disable-next-line camelcase
//   UNSAFE_componentWillMount() {
//     // console.log(this.props.user);
//     this.setState(prevState => ({
//       ...prevState,
//       user: {
//         ...prevState.user,
//         // name: this.props.user.name
//         //   ? this.props.user.name
//         //   : `${this.props.user.firstName} ${this.props.user.lastName}`,
//         name:
//           this.props.user.firstName && this.props.user.lastName
//             ? `${this.props.user.firstName} ${this.props.user.lastName}`
//             : 'Guest',
//         email: this.props.user.email,
//         organisation: this.props.user.organisation,
//         role: this.props.user.role,
//         avatar: this.props.user.avatar
//       }
//     }));
//   }

//   render() {
//     // const { user } = this.props
//     // const guestDropdown = (
//     //   <Menu.Item>
//     //     <a
//     //       href=""
//     //       onClick={e => {
//     //         e.preventDefault();
//     //         this.loginHandler();
//     //       }}
//     //     >
//     //       <i className={`${styles.menuIcon} icmn-enter`} />
//     //       <FormattedMessage id="topBar.profileMenu.login" />
//     //     </a>
//     //   </Menu.Item>
//     // );

//     // const memberDropdown = (
//     //   <MenuItemGroup>
//     //     <Menu.Item className="ant-dropdown-menu-item" key="user">
//     //       <strong>
//     //         <FormattedMessage id="topBar.profileMenu.name" />:{' '}
//     //         {this.state.user.name || 'Guest'}
//     //       </strong>
//     //       <div>
//     //         <strong>
//     //           <FormattedMessage id="topBar.profileMenu.role" />:{' '}
//     //         </strong>
//     //         {this.state.user.role}
//     //       </div>
//     //       <div>
//     //         <strong className="mr-1">
//     //           <FormattedMessage id="topBar.profileMenu.email" />:{' '}
//     //         </strong>
//     //         {this.state.user.email}
//     //       </div>
//     //       <div>
//     //         <strong>
//     //           <FormattedMessage id="topBar.profileMenu.organisation" />:{' '}
//     //         </strong>
//     //         {this.state.user.organisation}
//     //       </div>
//     //     </Menu.Item>
//     //     <Menu.Divider className="ant-dropdown-menu-item-divider" />
//     //     <Menu.Item className="ant-dropdown-menu-item" key="profile">
//     //       <Link to="/profile">
//     //         <i className={`${styles.menuIcon} icmn-user`} />
//     //         <FormattedMessage id="topBar.profileMenu.viewProfile" />
//     //       </Link>
//     //     </Menu.Item>
//     //     <Menu.Divider className="ant-dropdown-menu-item-divider" />
//     //     <Menu.Item className="ant-dropdown-menu-item" key="logout">
//     //       {/* <a href="javascript: void(0);" onClick={this.logoutHandler}> */}
//     //       <a
//     //         href=""
//     //         onClick={e => {
//     //           e.preventDefault();
//     //           this.logoutHandler();
//     //           this.props.history.push('/');
//     //         }}
//     //       >
//     //         <i className={`${styles.menuIcon} icmn-exit`} />
//     //         <FormattedMessage id="topBar.profileMenu.logout" />
//     //       </a>
//     //     </Menu.Item>
//     //   </MenuItemGroup>
//     // );

//     // // const { count } = this.state
//     // const menu = (
//     //   <Menu selectable={false}>
//     //     {this.props.user.authorized ? memberDropdown : guestDropdown}
//     //   </Menu>
//     // );

//     const guestMenuItems = [
//       {
//         key: 0,
//         label: (
//           <a
//             href=""
//             onClick={e => {
//               e.preventDefault();
//               this.loginHandler();
//             }}
//           >
//             <i className={`${styles.menuIcon} icmn-enter`} />
//             <FormattedMessage id="topBar.profileMenu.login" />
//           </a>
//         )
//       }
//     ];

//     const memberMenuItems = [
//       {
//         key: 'name',
//         label: (
//           <strong>
//             <FormattedMessage id="topBar.profileMenu.name" />:{' '}
//             {this.state.user.name || 'Guest'}
//           </strong>
//         )
//       }
//       // {
//       //   key: 'role',
//       //   label: (
//       //     <>
//       //       <strong>
//       //         <FormattedMessage id="topBar.profileMenu.role" />:{' '}
//       //       </strong>
//       //       {this.state.user.role}
//       //     </>
//       //   )
//       // },
//       // {
//       //   key: 'email',
//       //   label: (
//       //     <>
//       //       <strong className="mr-1">
//       //         <FormattedMessage id="topBar.profileMenu.email" />:{' '}
//       //       </strong>
//       //       {this.state.user.email}
//       //     </>
//       //   )
//       // },
//       // {
//       //   key: 'organisation',
//       //   label: (
//       //     <>
//       //       <strong>
//       //         <FormattedMessage id="topBar.profileMenu.organisation" />:{' '}
//       //       </strong>
//       //       {this.state.user.organisation}
//       //     </>
//       //   )
//       // },
//       // {
//       //   key: 'divider',
//       //   label: <Divider className="ant-dropdown-menu-item-divider" />
//       // },
//       // {
//       //   key: 'profile',
//       //   label: (
//       //     <Link to="/profile">
//       //       <i className={`${styles.menuIcon} icmn-user`} />
//       //       <FormattedMessage id="topBar.profileMenu.viewProfile" />
//       //     </Link>
//       //   )
//       // },
//       // {
//       //   key: 'divider2',
//       //   label: <Divider className="ant-dropdown-menu-item-divider" />
//       // },
//       // {
//       //   key: 'logout',
//       //   label: (
//       //     <a
//       //       href=""
//       //       onClick={e => {
//       //         e.preventDefault();
//       //         this.logoutHandler();
//       //         this.props.history.push('/');
//       //       }}
//       //     >
//       //       <i className={`${styles.menuIcon} icmn-exit`} />
//       //       <FormattedMessage id="topBar.profileMenu.logout" />
//       //     </a>
//       //   )
//       // }
//     ];

//     const menuItems = this.props.user.authorized
//       ? memberMenuItems
//       : guestMenuItems;

//     console.log(menuItems);

//     return (
//       <Dropdown menu={{ menuItems }}>
//         <div className={styles.dropdown}>
//           <div className={styles.flag}>
//             <Avatar
//               className={styles.avatar}
//               shape="square"
//               size="large"
//               icon={<UserOutlined />}
//               src={<img src={this.state.user.avatar} alt="pic" />}
//             />
//           </div>
//         </div>
//       </Dropdown>
//       // <div className={styles.user}>
//       //   <strong>
//       //     <FormattedMessage id="topBar.profileMenu.hello" />,
//       //   </strong>
//       //   <Dropdown menu={menuItems} trigger={['hover']}>
//       //     <div className={styles.dropdown}>
//       //       <strong>{this.state.user.name}</strong>
//       //       <Badge>
//       //         <Avatar
//       //           className={styles.avatar}
//       //           shape="square"
//       //           size="large"
//       //           icon={<UserOutlined />}
//       //           src={this.state.user.avatar}
//       //         />
//       //       </Badge>
//       //     </div>
//       //   </Dropdown>
//       // </div>
//     );
//   }
// }

// // export default ProfileMenu;
// export default connect(mapStateToProps)(ProfileMenu);
