import React from 'react';
import { FormattedMessage } from 'react-intl';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { notification } from 'antd';
import { userId, isAuthorized } from '../../components/data/constants';

import { samlLogin, samlLogout, authStatusCheck } from '../../services/user';
import actions from './actions';

// SAML
export function* SAML_LOGIN() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true
    }
  });
  const success = yield call(samlLogin);
  if (success) {
    notification.success({
      message: 'Logged In'
      // description: 'You have successfully logged in to Total Cost of Ownership (TCO) Tool',
    });
    yield put({
      type: 'user/SAML_GET_CURRENT_ACCOUNT'
    });
  }
}

export function* SAML_LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      firstName: <FormattedMessage id="topBar.profileMenu.guest" />,
      lastName: '',
      role: 'guest',
      email: '',
      organisation: '',
      avatar: '',
      token: '',
      authorized: false,
      loading: false
    }
  });
  yield call(samlLogout);
}

export function* SAML_GET_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true
    }
  });
  const authCheck = yield call(authStatusCheck);
  // console.log(authCheck)
  if (authCheck) {
    switch (authCheck.status) {
      case 'LoggedOut': // clear details in redux
        yield put({
          type: 'user/SET_STATE',
          payload: {
            id: userId, // set to empty
            firstName: <FormattedMessage id="topBar.profileMenu.guest" />,
            lastName: '',
            role: 'guest',
            email: '',
            organisation: '',
            avatar: '',
            token: '',
            authorized: isAuthorized, // set to false
            loading: false
          }
        });
        break;

      case 'NoSessCookie':
      case 'NoSession':
      case 'ExpiredCookie':
        yield put({
          type: 'user/SAML_LOGIN'
        });
        break;
      case 'OK':
        // eslint-disable-next-line
        const {
          id,
          firstName,
          lastName,
          organisation,
          email,
          avatar
        } = authCheck.data;
        // eslint-disable-next-line
        const { token } = authCheck;
        yield put({
          type: 'user/SET_STATE',
          payload: {
            id,
            firstName,
            lastName,
            email,
            organisation,
            avatar,
            role: organisation === 'Fleet Forum' ? 'Administrator' : 'Member',
            token,
            authorized: true
          }
        });
        break;

      default:
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false
          }
        });
        break;
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAML_LOGIN, SAML_LOGIN),
    takeEvery(actions.SAML_LOGOUT, SAML_LOGOUT),
    takeEvery(actions.SAML_GET_CURRENT_ACCOUNT, SAML_GET_CURRENT_ACCOUNT),
    SAML_GET_CURRENT_ACCOUNT() // run once on app load to check user auth
  ]);
}
