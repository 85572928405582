import { ConfigProvider } from 'antd';
import localeData from 'react-intl/locale-data/es';

const messages = {
  // Shared text
  'topBar.home': `Inicio`,
  'topBar.langEn': `English`,
  'topBar.langFr': `Français`,
  'topBar.langEs': `Español`,
  'common.siteTitle': `Kit de herramientas del costo total de propiedad (TCO)`,
  'common.kmFull': `Kilómetro`,
  'common.mileFull': `Milla`,
  'common.perKm': `por km`,
  'common.perMil': `por milla`,
  'common.perDay': `por día`,
  'common.day': `Día`,
  'common.days': `Días`,
  'common.year': `Año`,
  'common.years': `Años`,
  'common.perMonthAbbr': `PM`,
  'common.perYearAbbr': `año`,
  'common.vehicle': `Vehículo`,
  'common.costPerKm': `Costos por kilómetro`,
  'common.costPerMil': `Costos por milla`,
  'common.costPerDay': `Costos por día`,
  'common.export.title': `Exportar informes`,
  'common.export.pdf': `PDF (tablas + datos)`,
  'common.export.excel': `Excel (solo datos)`,
  'common.export.image': `Image (solo tablas)`,
  'common.conclusion.title': `Conclusión`,
  'common.conclusion.placeholder': `Se mostrará después del cálculo`,
  'common.conclusion.text': `Si utilizó un {cheaperVehicle} en lugar de un {expVehicle}, ahorraría {distSavings} por {distanceUnit} conducido (o {annualSavings} por año) en un periodo de {period} años.`,
  'common.footer.text': `Esta herramienta le ayuda a calcular y comparar los costos de comprar vehículos frente a los de alquilarlos. Utiliza sus datos para determinar los gastos incurridos en ambos casos. A continuación, calcula los costos por día, por año y por kilómetro/milla.`,
  'common.footer.rights': `Todos los derechos reservados`,
  'common.footer.disclaimerTitle': `Exención de responsabilidad`,
  'common.footer.disclaimerText': `Esta herramienta proporciona orientación sobre los costos de su flota, que es sólo un aspecto de la gestión de flotas. Fleet Forum no se hace responsable de ninguna consecuencia de las decisiones que usted tome utilizando la calculadora de TCO. Por lo tanto, usted es el único responsable de la confianza que deposite en la información obtenida de la calculadora de TCO.`,
  'common.report.ready': `El informe está listo para descargar`,
  'common.report.cancel': `Cancelar`,
  'common.report.download': `Descargar informe`,
  'charts.totalDailyCost': `Costo diario total`,
  'charts.totalAnnualCost': `Costo anual total`,

  // Charts
  'charts.vehicle': `Vehículo`,
  'charts.vehicleA': `Vehículo 1`,
  'charts.vehicleB': `Vehículo 2`,
  'charts.owning': `Comprar`,
  'charts.renting': `Alquilar`,
  'charts.fuel': `Combustible`,
  'charts.tyres': `Neumáticos`,
  'charts.depreciation': `Depreciación`,
  'charts.maintenance': `Mantenimiento`,
  'charts.insurance': `Seguro`,
  'charts.labor': `Mano de obra`,
  'charts.admin': `Admin`,
  'charts.breakdown': `Avería`,
  'charts.repair': `Reparar`,
  'charts.roadTax': `Impuesto de carretera`,
  'charts.other': `Otro`,
  'charts.rental': `Alquilar`,
  'charts.vehSelReport': `Informe de selección de vehículos`,
  'charts.owningA': `Comprar a `,
  'charts.rentingA': `Alquilar de`,
  'charts.costPer': `Costo por`,
  'charts.plan': `Plan`,
  'charts.barDailyTitle': `Comparación de los costos diarios del vehículo`,
  'topBar.profileMenu.guest': `Invitado`,
  'topBar.profileMenu.hello': `Hola`,
  'topBar.profileMenu.name': `Nombre`,
  'topBar.profileMenu.role': `Rol`,
  'topBar.profileMenu.email': `Correo electrónico`,
  'topBar.profileMenu.organisation': `Organización`,
  'topBar.profileMenu.viewProfile': `Ver perfil`,
  'topBar.profileMenu.login': `Inicio de sesión`,
  'topBar.profileMenu.logout': `Cierre de sesión`,

  // Forms
  'forms.gen.title': `Unidades estándar`,
  'forms.gen.calcUsd': `Calcular en USD`,
  'forms.gen.savedDs': ` Conjuntos de datos guardados`,
  'forms.gen.optSelectDs': `--Seleccionar conjunto de datos--`,
  'forms.gen.load': `Cargar`,
  'forms.gen.dsName': `Nombre del conjunto de datos`,
  'forms.gen.dsName.info': `Nombre de su conjunto de datos`,
  'forms.gen.dsName.placeholder': `Mi primer conjunto de datos`,
  'forms.gen.saveDs': `Guardar`,
  'forms.gen.updateDs': `Actualizar`,
  'forms.gen.lblCountry': `Seleccionar país`,
  'forms.gen.optSelectCountry': `--Seleccionar país--`,
  'forms.gen.lblSelCurrency': `Seleccionar moneda`,
  'forms.gen.optSelectCurrency': `—Seleccionar moneda--`,
  'forms.gen.localReg': `Región local`,
  'forms.gen.localReg.info': `La ciudad / región dentro de este país a la que se refiere el conjunto de datos`,
  'forms.gen.localReg.placeholder': `Mi región`,
  'forms.gen.lblDsUnits': `Unidades de distancia`,
  'forms.gen.lblFuelUnits': `Unidades de combustible`,
  'forms.gen.lblCurrency': `Moneda`,
  'forms.gen.currency.info': `Moneda a utilizar en este cálculo`,

  'forms.workingDays': `Días laborables`,
  'forms.workingDays.info': `El número de días laborables en un año. El valor estándar es de 250 días, basado en una semana laboral de 5 días y 10 días festivos. El valor puede ajustarse.`,
  'forms.dailyMil': `Kilometraje diario`,
  'forms.dailyMil.info': `Distancia media recorrida al día`,
  'forms.vehType': `Tipo de vehículo`,
  'forms.optSelVehType': `--Seleccionar tipo de vehículo--`,
  'forms.fuelType': `Tipo de combustible / energía`,
  'forms.optSelFuelType': `--Seleccionar tipo de combustible / energía--`,
  'forms.vehDescription': `Descripción del vehículo`,
  'forms.vehDescription.info': `Introduzca algunos detalles del vehículo, por ejemplo, Marca, Modelo, Año, Cilindrada, Tipo de combustible, etc.`,
  'forms.fuelPrice': `Precio del combustible / energía`,
  'forms.fuelPrice.info': `Costo por unidad de combustible / energía para este vehículo`,
  'forms.fuelConsumption': `Consumo de combustible / energía`,
  'forms.fuelConsumption.info': `Consumo promedio de combustible de este vehículo `,
  'forms.tyreMil': `Kilometraje de los neumáticos`,
  'forms.tyreMil.info': ` Kilometraje promedio entre cambios de neumáticos`,
  'forms.lblAdmin': `Costos administrativos mensuales`,
  'forms.admin.info': `Costos asociados a la gestión del vehículo. Puede incluir el salario del jefe de flota, los costos incurridos por la gestión de la flota o los costos de seguimiento del vehículo, etc. En caso de que no disponga de un jefe de flota dedicado, tome un porcentaje del salario del miembro del personal encargado de la flota que se gasta en ella.`,
  'forms.labor': `Costos laborales mensuales`,
  'forms.labor.info': `Costo mensual laboral del vehículo, por ejemplo, salario y viáticos del conductor`,
  'forms.depreciation': `Período de depreciación`,
  'forms.depreciation.info': `El número de años que se utiliza (y deprecia) el vehículo antes de deshacerse de él. El periodo de depreciación suele describirse en la póliza de transporte o del vehículo.`,
  'forms.acqValue': `Valor de adquisición`,
  'forms.acqValue.info': `Costo de puesta en servicio del vehículo. También incluye el coste del transporte desde el proveedor hasta el lugar de operación; por ejemplo, costos de importación, de matrícula, etc.`,
  'forms.maintenance': `Costo de mantenimiento`,
  'forms.maintenance.info': `Costo anual del mantenimiento preventivo programado`,
  'forms.insurance': `Costo anual del seguro`,
  'forms.insurance.info': `Costo de las primas de seguro al año`,
  'forms.tyres': `Costo de los neumáticos`,
  'forms.tyres.info': `Costo medio por juego de neumáticos para este vehículo`,
  'forms.repairs': `Costo de reparación`,
  'forms.repairs.info': `Costo de las reparaciones imprevistas y no programadas al año`,
  'forms.numBreakdowns': `Número de averías`,
  'forms.numBreakdowns.info': `El número de veces que el vehículo se avería inesperadamente. Por ejemplo, debido a un pinchazo, a un atasco en el terreno o a un incidente con el vehículo`,
  'forms.breakdownCost': `Costo medio de la avería`,
  'forms.breakdownCost.info': `el coste de recuperación del vehículo desde el lugar de la avería hasta la base. Esto incluye los costos de los servicios de grúa o si hay que enviar otro vehículo para recuperar el vehículo averiado`,
  'forms.taxes': `Impuesto anual de circulación`,
  'forms.taxes.info': `Impuesto periódico que grava a los vehículos de motor que utilizan las vías públicas`,
  'forms.disposalMeth': `Método de liquidación`,
  'forms.optDisposalMeth': `Venta / Subasta`,
  'forms.disposalVal': `Valor de liquidación`,
  'forms.disposalVal.info': `La cantidad de dinero que obtendría por vender / subastar este vehículo después de su periodo de depreciación. Rellene el valor neto (precio de venta - costos incurridos para vender el vehículo). En caso de que la organización done el vehículo, este valor es cero`,
  'forms.calc': `Calcular`,
  'forms.reset': `Restablecer formulario`,

  // Vehicle Selection Specific
  'topBar.veh.title': `Selección de vehículo`,
  'forms.veh.title': ` Datos del Vehículo`,
  'forms.veh.two': ` Comparar dos`,
  'forms.veh.one': `Estimar uno`,
  'forms.veh.both': `Ambos`,
  'forms.veh.a': `Vehículo 1`,
  'forms.veh.b': `Vehículo 2`,
  'forms.veh.both.heading': ` Datos idénticos para ambos tipos de vehículos`,
  'veh.report.title': `Informe de selección de vehículo`,

  // OvR specific
  'topBar.ovr.title': `Vehículo propio VS vehículo de alquiler`,
  'forms.ovr.own': `Propio`,
  'forms.ovr.rent': `Alquilar`,
  'forms.ovr.dailyRental': ` Precio diario de alquiler`,
  'forms.ovr.dailyRental.info': `Dinero a pagar por día para alquilar este vehículo`,
  'forms.ovr.selective': `Selectivo`,
  'forms.ovr.allInc': `Todo incluido`,
  'forms.ovr.priceIncl': `Precio inclusivo`,
  'forms.ovr.separate': `Costo separado`,
  'forms.ovr.rentalFuelInc': `Costo de combustible incluido`,
  'forms.ovr.rentalFuelSep': `Costo de combustible por separado`,
  'forms.ovr.fuelCost': ` Costo total del combustible`,
  'forms.ovr.fuelCost.info': `Costo total del abastecimiento de combustible del vehículo'. Introduzca el coste del combustible si no está incluido en los costos de alquiler del vehículo; en caso contrario, márquelo como incluido.`,
  'ovr.conclusion.text': `tbc`,

  // Notifications, errors and warnings
  'notification.info.genReport': `Generando informe, por favor espere...`,
  'notification.info.calUsd': `¡PANEL DE RESULTADOS ACTUALIZADO! Esto es temporal y volverá a la moneda seleccionada cuando vuelva a calcular o navegue fuera de esta página.`,
  'notification.error.invalidCurrency': `Moneda seleccionada no válida`,
  'notification.error.calcError': `Introduzca todos los datos necesarios y, a continuación, haga clic en el botón 'calcular.`,
  'notification.error.invalidExp': `Formato de exportación no válido`,
  'notification.info.updating': `Actualizando...`,
  'notification.info.saving': `Guardando...`,
  'notification.info.wait': `Espere, por favor...`,
  'notification.warn.title': `¡Atención!`,
  'notification.warn.interrupted': `Proceso interrumpido`,
  'notification.warn.interrupted.info': `El proceso se ha interrumpido, espere un momento e inténtelo de nuevo.`,
  'notification.warn.loadDs': `Seleccione y cargue el conjunto de datos que desea actualizar o guarde uno nuevo.`,
  'notification.error.title': `Error!`,
  'notification.error.saving': `Se ha producido un error al guardar los datos`,
  'notification.error.updating': `Se ha producido un error al actualizar los datos`,
  'notification.error.saving.vehA': `Se ha producido un error al guardar los datos del vehículo 1`,
  'notification.error.updating.vehA': `Se ha producido un error al actualizar los datos del vehículo 1`,
  'notification.error.saving.vehB': `Se ha producido un error al guardar los datos del vehículo 2`,
  'notification.error.updating.vehB': `Se ha producido un error al actualizar los datos del vehículo 2`,
  'notification.info.success': `¡Éxito!`,
  'notification.info.successSaving': `Sus datos se han guardado correctamente`,
  'notification.info.successUpdating': `Sus datos se han actualizado correctamente`,
  'notification.warn.login': `Por favor, inicie sesión primero`,
  'notification.error.loadDs': `Seleccione un conjunto de datos para cargar`,
  'notification.warn.fixSave': `corrija los problemas resaltados en las unidades estándar antes de guardar`,
  'notification.warn.fixUpd': `corrija los problemas resaltados en las unidades estándar antes de actualizar`,
  'notification.warn.fixCalc': `corrija los problemas resaltados en las unidades estándar antes de calcular`,
  'notification.warn.fixCalc2': `corrija los problemas resaltados antes de calcular`,
  'notification.warn.fixCalcUsd': `corrija los problemas resaltados en la sección Unidades estándar antes de convertir a USD`,
  'notification.info.loadDs': `¿Cargar el conjunto de datos?`,
  'notification.info.loadDs.confirm': `Cargar el conjunto de datos sobrescribirá los datos introducidos actualmente en el formulario. ¿Desea continuar?`,
  'notification.error.invalidDs': `Conjunto de datos no válido seleccionado`,
  'notification.error.fixData.save': `corrija los problemas resaltados en la sección de datos del vehículo antes de guardar`,
  'notification.error.fixData.update': `corrija los problemas resaltados en la sección de datos del vehículo antes de actualizar`,

  // Form errors
  'forms.err.dsName': `Escriba un nombre para el conjunto de datos`,
  'forms.err.selCountry': `Seleccione un país`,
  'forms.err.required': `Obligatorio`,
  'forms.warn.fix': `Solucione los problemas resaltados antes de continuar`,
  'forms.err.zero': `el valor no puede ser cero`,
  'forms.err.vehType': `Seleccione un tipo de vehículo`,
  'forms.err.fuelType': `Seleccione el tipo de combustible`,
  'forms.err.vehDescription': `Introduzca la descripción del vehículo`,

  // Extras
  'extras.country': `País`,
  'extras.created': ` Creado el `,
  'extras.modified': `Última modificación`,
  'extras.delete': `Borrar`,
  'extras.actions': `Medidas`,
  'extras.datasets': `Conjuntos de datos`,
  'extras.ovr': ` Comprar o alquilar`,
  'extras.profile': `Perfil`,
  'extras.bought': `compró`,
  'extras.buying': `comprando`,
  'extras.rented': `alquilado`,
  'extras.renting': `alquilando`,
  'extras.ownSummary': `Si usara {cheaperOpt} un {vehicleDescription} en vez de un{expOption}, ahorraría {currency} {annualSavings} por año (o {currency} {distanceSavings} por {distanceUnit} recorrido) a lo largo de un período de {period} años.`,
  'extras.optSelDistUnit': `-- Seleccione la unidad de distancia--`,
  'extras.optSelFuelUnit': `-- Seleccione la unidad de combustible--`,
  'extras.optSelDispMeth': `-- Seleccione el método de retirada del vehículo--`
};

export default {
  locale: `es-ES`,
  ConfigProvider,
  localeData,
  messages
};
