// import * as actionTypes from './generalActions';
import { localStorageTest } from './localStorage/functions'

const initialState = {
  currentDataset: '',
  datasetName: '',
  distanceUnit: 'Km',
  distanceUnitId: '',
  fuelUnit: 'Ltr',
  fuelUnitId: '',
  currency: 'Eur',
  countryCode: '',
  country: '',
  localRegion: '',
  isLSavailable: localStorageTest(),
  genFormNoErrors: false,
  userDataLoadTime: '',
}

const reducer = (state = initialState, action) => {
  const newState = { ...state }

  Object.keys(initialState).forEach(function eachKey(key) {
    if (key === action.type) {
      newState[key] = action.payload
      state = newState
    }
  })
  return state
}

export default reducer
