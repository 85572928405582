// export const BACKEND_URL = 'https://backend.tco.fleetforum.org';
// export const BACKEND_URL = 'https://backend.dev.fleetforum.org';
// export const BACKEND_URL = 'http://localhost:5000';
const backendPort = 5000;
const envConstants = {
  local: {
    BACKEND_URL: `http://localhost:${backendPort}`,
    BACKEND_API_URL: `http://localhost:${backendPort}/graphql`,
    userId: '',
    isAuthorized: false
  },
  dev: {
    BACKEND_URL: 'https://backend.dev.fleetforum.org',
    BACKEND_API_URL: 'https://backend.dev.fleetforum.org/graphql',
    userId: '',
    isAuthorized: false
  },
  live: {
    BACKEND_URL: 'https://backend.tco.fleetforum.org',
    BACKEND_API_URL: 'https://backend.tco.fleetforum.org/graphql',
    userId: '',
    isAuthorized: false
  }
};

const env = 'live';

export const { BACKEND_URL } = envConstants[env];
export const { BACKEND_API_URL } = envConstants[env];
export const { userId } = envConstants[env];
export const { isAuthorized } = envConstants[env];
