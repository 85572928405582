import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <div className="mr-4">
          <img
            src="resources/images/tco/FleetForumLogo-1.png"
            rel="noopener noreferrer"
            alt="Fleet Forum"
            className={styles.logo}
          />
        </div>
        <div className="ml-auto">
          <h1 className="h1">
            <FormattedMessage id="common.siteTitle" />
          </h1>
        </div>
      </div>
    );
  }
}

export default TopBar;
