/* eslint-disable import/extensions */
import React from 'react';
// import { LocaleProvider } from 'antd'
import { ConfigProvider } from 'antd';
import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';
import english from '../../../locales/en-US';
import french from '../../../locales/fr-FR';
import spanish from '../../../locales/es-ES';
// import russian from '../../../locales/ru-RU';
// import chinese from '../../../locales/zh-CN';

addLocaleData(english.localeData);
addLocaleData(french.localeData);
addLocaleData(spanish.localeData);
// addLocaleData(russian.localeData);
// addLocaleData(chinese.localeData);

const locales = {
  'en-US': english,
  'fr-FR': french,
  'es-ES': spanish
  // 'ru-RU': russian,
  // 'zh-CN': chinese
};

// standardize locale : convert en to en-US
const localeSelector = localeInput => {
  switch (localeInput) {
    case 'en':
    case 'en-US':
      return 'en-US';
    case 'fr':
    case 'fr-FR':
      return 'fr-FR';
    case 'es':
    case 'es-ES':
      return 'es-ES';
    default:
      return 'en-US';
  }
};

// @connect(({ settings }) => ({ settings }))
const mapStateToProps = ({ settings }) => ({ settings });

class Localization extends React.Component {
  render() {
    const {
      children,
      settings: { locale }
    } = this.props;
    const currentLocale = locales[localeSelector(locale)];
    // console.log('selected locale:', locale);
    // console.log('Current locale:', currentLocale);

    return (
      <ConfigProvider locale={currentLocale.antdData}>
        <IntlProvider
          locale={currentLocale.locale}
          messages={currentLocale.messages}
        >
          {children}
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

// export default Localization;
export default connect(mapStateToProps)(Localization);
