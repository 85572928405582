import React from 'react';
import { Dropdown } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

import FlagEn from './flags/en.svg';
import FlagFr from './flags/fr.svg';
import FlagEs from './flags/es.svg';
// import FlagZh from './flags/zh.svg';

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale
});

const LanguageSwitcher = ({ dispatch, locale }) => {
  const mapFlags = {
    en: FlagEn,
    fr: FlagFr,
    es: FlagEs
    // zh: FlagZh
  };

  const changeLanguage = ({ key }) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key
      }
    });
    // reload page to reload charts data - temporary
    window.location.reload(false);
  };

  const language = locale.substr(0, 2);

  // const menu = (
  //   <Menu selectedKeys={[locale]} onClick={changeLanguage}>
  //     <Menu.Item key="en-US">
  //       <span className={styles.menuIcon}>
  //         <img src={mapFlags.en} alt="English" />
  //       </span>
  //       English
  //     </Menu.Item>
  //     <Menu.Item key="fr-FR">
  //       <span className={styles.menuIcon}>
  //         <img src={mapFlags.fr} alt="Français" />
  //       </span>
  //       Français
  //     </Menu.Item>
  //     <Menu.Item key="es-ES">
  //       <span className={styles.menuIcon}>
  //         <img src={mapFlags.es} alt="Español" />
  //       </span>
  //       Español
  //     </Menu.Item>
  //     {/* <Menu.Item key="zh-CN">
  //       <span className={styles.menuIcon}>
  //         <img src={mapFlags.zh} alt="简体中文" />
  //       </span>
  //       简体中文
  //     </Menu.Item> */}
  //   </Menu>
  // );

  const items = [
    {
      key: 'en-US',
      label: (
        <>
          <span className={styles.menuIcon}>
            <img
              src={mapFlags.en}
              alt={<FormattedMessage id="topBar.langEn" />}
            />
          </span>
          <FormattedMessage id="topBar.langEn" />
        </>
      )
    },
    {
      key: 'fr-FR',
      label: (
        <>
          <span className={styles.menuIcon}>
            <img
              src={mapFlags.fr}
              alt={<FormattedMessage id="topBar.langFr" />}
            />
          </span>
          <FormattedMessage id="topBar.langFr" />
        </>
      )
    },
    {
      key: 'es-ES',
      label: (
        <>
          <span className={styles.menuIcon}>
            <img
              src={mapFlags.es}
              alt={<FormattedMessage id="topBar.langEs" />}
            />
          </span>
          <FormattedMessage id="topBar.langEs" />
        </>
      )
    }
  ];

  const menuProps = {
    items,
    onClick: changeLanguage
  };

  return (
    <Dropdown menu={menuProps} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <div className={styles.flag}>
          <img src={mapFlags[language]} alt={language} />
          <span>{language}</span>
        </div>
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(LanguageSwitcher);
