import { ConfigProvider } from 'antd';
import localeData from 'react-intl/locale-data/en';

const messages = {
  // Shared text
  'topBar.home': `Home`,
  'topBar.langEn': `English`,
  'topBar.langFr': `Français`,
  'topBar.langEs': `Español`,
  'common.siteTitle': `Total Cost of Ownership (TCO) Toolkit`,
  'common.kmFull': `Kilometre`,
  'common.mileFull': `Mile`,
  'common.perKm': `per km`,
  'common.perMil': `per mile`,
  'common.perDay': `per day`,
  'common.day': `Day`,
  'common.days': `Days`,
  'common.year': `Year`,
  'common.years': `Years`,
  'common.perMonthAbbr': `PM`,
  'common.perYearAbbr': `yr`,
  'common.vehicle': `Vehicle`,
  'common.costPerKm': `Costs per Kilometre`,
  'common.costPerMil': `Costs per Mile`,
  'common.costPerDay': `Costs per Day`,
  'common.export.title': `Export Reports`,
  'common.export.pdf': `PDF (charts + data)`,
  'common.export.excel': `Excel (data only)`,
  'common.export.image': `Image (charts only)`,
  'common.conclusion.title': `Conclusion`,
  'common.conclusion.placeholder': `Will be displayed after calculation`,
  'common.conclusion.text': `If you used a {cheaperVehicle} instead of a {expVehicle}, you would save {distSavings} per {distanceUnit} driven (or {annualSavings} per year) over a period of {period} years.`,
  'common.footer.text': `This tool helps you calculate and compare the costs of purchasing vehicles vis a vis renting / hiring one. It utilizes your data to determine the expenses incurred on either scenario. It then calculates the costs per day, per year and per kilometre/mile.`,
  'common.footer.rights': `All rights reserved`,
  'common.footer.disclaimerTitle': `Disclaimer`,
  'common.footer.disclaimerText': `This tool aims to provide guidance around the costs of your fleet; which is only one aspect of fleet management. Fleet Forum is not liable for any consequence of decisions you make using the TCO calculator. You are therefore solely responsible for any reliance you place on information gained from the TCO calculator.`,
  'common.report.ready': `Your Report is ready for download`,
  'common.report.cancel': `Cancel`,
  'common.report.download': `Download Report`,
  'charts.totalDailyCost': `Total Daily Cost`,
  'charts.totalAnnualCost': `Total Annual Cost`,

  // Charts
  'charts.vehicle': `Vehicle`,
  'charts.vehicleA': `Vehicle 1`,
  'charts.vehicleB': `Vehicle 2`,
  'charts.owning': `Owning`,
  'charts.renting': `Renting`,
  'charts.fuel': `Fuel`,
  'charts.tyres': `Tyres`,
  'charts.depreciation': `Depreciation`,
  'charts.maintenance': `Maintenance`,
  'charts.insurance': `Insurance`,
  'charts.labor': `Labor`,
  'charts.admin': `Admin`,
  'charts.breakdown': `Breakdown`,
  'charts.repair': `Repair`,
  'charts.roadTax': `Road Tax`,
  'charts.other': `Other`,
  'charts.rental': `Rental`,
  'charts.vehSelReport': `Vehicle Selection Report`,
  'charts.owningA': `Owning a`,
  'charts.rentingA': `Renting a`,
  'charts.costPer': `Cost per`,
  'charts.plan': `Plan`,
  'charts.barDailyTitle': `Daily Vehicle Costs Comparison`,

  'topBar.profileMenu.guest': `Guest-en`,
  'topBar.profileMenu.hello': `Hello`,
  'topBar.profileMenu.name': `Name`,
  'topBar.profileMenu.role': `Role`,
  'topBar.profileMenu.email': `Email`,
  'topBar.profileMenu.organisation': `Organisation`,
  'topBar.profileMenu.viewProfile': `View Profile`,
  'topBar.profileMenu.login': `Login`,
  'topBar.profileMenu.logout': `Logout`,

  // Forms
  'forms.gen.title': `Standard Units`,
  'forms.gen.calcUsd': `Calculate in USD`,
  'forms.gen.savedDs': `Saved Datasets`,
  'forms.gen.optSelectDs': `--Select Datset--`,
  'forms.gen.load': `Load`,
  'forms.gen.dsName': `Dataset Name`,
  'forms.gen.dsName.info': `Name of your Dataset`,
  'forms.gen.dsName.placeholder': `My first dataset`,
  'forms.gen.saveDs': `Save`,
  'forms.gen.updateDs': `Update`,
  'forms.gen.lblCountry': `Select Country`,
  'forms.gen.optSelectCountry': `--Select Country--`,
  'forms.gen.lblSelCurrency': `Select Currency`,
  'forms.gen.optSelectCurrency': `--Select Currency--`,
  'forms.gen.localReg': `Local Region`,
  'forms.gen.localReg.info': `The city / region within this country that the dataset relates to`,
  'forms.gen.localReg.placeholder': `My Region`,
  'forms.gen.lblDsUnits': `Distance Units`,
  'forms.gen.lblFuelUnits': `Fuel Units`,
  'forms.gen.lblCurrency': `Currency`,
  'forms.gen.currency.info': `Currency to use in this calculation`,

  'forms.workingDays': `Working days`,
  'forms.workingDays.info': `The number of working days in a year. The standard is set to 250 days, based on a 5-day working week and 10 public holidays. The value can be adjusted.`,
  'forms.dailyMil': `Daily mileage`,
  'forms.dailyMil.info': `Average distance covered per day`,
  'forms.vehType': `Vehicle type`,
  'forms.optSelVehType': `--Select Vehicle Type--`,
  'forms.fuelType': `Fuel / energy type`,
  'forms.optSelFuelType': `--Select Fuel / energy Type--`,
  'forms.vehDescription': `Vehicle description`,
  'forms.vehDescription.info': `Enter some details of the vehicle, e.g. Make, Model, Year, Engine Capacity, Fuel Type etc.`,
  'forms.fuelPrice': `Fuel / energy price`,
  'forms.fuelPrice.info': `Cost per unit of fuel / energy for this vehicle`,
  'forms.fuelConsumption': `Fuel / energy consumption`,
  'forms.fuelConsumption.info': `Average fuel consumption for this vehicle`,
  'forms.tyreMil': `Tyres mileage`,
  'forms.tyreMil.info': `Average mileage between tyre changes`,
  'forms.lblAdmin': `Monthly admin costs`,
  'forms.admin.info': `Costs associated with the management of the vehicle. You can include the fleet manager's salary, costs incured for fleet management or vehicle tracking costs etc. In case you do not have a dedicated fleet manager, please take a percentage of the salary of the staff member in charge of the fleet spends on it.`,
  'forms.labor': `Monthly labor costs`,
  'forms.labor.info': `Monthly labor cost of the vehicle, e.g. driver's salary & per diem`,
  'forms.depreciation': `Depreciation Period`,
  'forms.depreciation.info': `The number of years that the vehicle is used (and written off) before it is disposed off. The depreciation period is often described in the transport or vehicle policy.`,
  'forms.acqValue': `Acquisition value`,
  'forms.acqValue.info': `Cost of putting this vehicle into service. Also includes cost of transportation from the supplier ot the location of operation; e.g. import costs, registration costs etc`,
  'forms.maintenance': `Maintenance cost`,
  'forms.maintenance.info': `The cost of preventive, scheduled maintenance per year`,
  'forms.insurance': `Annual insurance cost`,
  'forms.insurance.info': `Cost of the insurance premiums per year`,
  'forms.tyres': `Cost of tyres`,
  'forms.tyres.info': `Average cost per set of tyres for this vehicle`,
  'forms.repairs': `Repair cost`,
  'forms.repairs.info': `The cost of unexpected, unscheduled repairs per year`,
  'forms.numBreakdowns': `Num. of breakdown`,
  'forms.numBreakdowns.info': `The number of times that the vehicle breaks down unexpectedly. Fore example because of a flat tyre, getting stuck in the terrain or after a vehicle incident`,
  'forms.breakdownCost': `Avg. breakdown cost`,
  'forms.breakdownCost.info': `the cost of retrieving the vehicle from the breakdown scene to the base. This includes costs for towing services or if another vehicle has to be sent to get the broken down vehicle back`,
  'forms.taxes': `Annual road taxes`,
  'forms.taxes.info': `A periodic tax payable on motor vehicles using public roads`,
  'forms.disposalMeth': `Disposal method`,
  'forms.optDisposalMeth': `Sale / Auction`,
  'forms.disposalVal': `Disposal value`,
  'forms.disposalVal.info': `The amount of money you'd get for selling / auctioning off this vehicle after its depreciation period. Fill in the net value (sales price - costs incurred to sell the vehicle). In case the organisation donates the vehicle, this value is zero`,
  'forms.calc': `Calculate`,
  'forms.reset': `Reset Form`,

  // Vehicle Selection Specific
  'topBar.veh.title': `Vehicle Selection`,
  'forms.veh.title': `Vehicle Data`,
  'forms.veh.two': `Compare Two`,
  'forms.veh.one': `Estimate One`,
  'forms.veh.both': `Both`,
  'forms.veh.a': `Vehicle 1`,
  'forms.veh.b': `Vehicle 2`,
  'forms.veh.both.heading': `Data that is identical for both types of vehicles`,
  'veh.report.title': `Vehicle Selection Report`,

  // OvR specific
  'topBar.ovr.title': `Owning VS Renting a Vehicle`,
  'forms.ovr.own': `Own`,
  'forms.ovr.rent': `Rent`,
  'forms.ovr.dailyRental': `Daily rental price`,
  'forms.ovr.dailyRental.info': `Money payable per day to rent this vehicle`,
  'forms.ovr.selective': `Selective`,
  'forms.ovr.allInc': `All Inclusive`,
  'forms.ovr.priceIncl': `Price Inclusive`,
  'forms.ovr.separate': `Separate Cost`,
  'forms.ovr.rentalFuelInc': `Fuel cost included`,
  'forms.ovr.rentalFuelSep': `Fuel cost separate`,
  'forms.ovr.fuelCost': `Total Fuel cost`,
  'forms.ovr.fuelCost.info': `Total cost of fuelling the vehicle. Enter the fuel cost if that is not included in the vehicle hire costs, else, mark it as inclusive.`,
  'ovr.conclusion.text': `tbc`,

  // Notificatons, errors and warnings
  'notification.info.genReport': `Generating Report, please wait...`,
  'notification.info.calUsd': `RESULTS PANEL UPDATED! This is temporary and will revert to the selected currency when you recalculate or navigate away from this page.`,
  'notification.error.invalidCurrency': `Invalid currency selected`,
  'notification.error.calcError': `Please fill in all required data then click on the 'calculate' button.`,
  'notification.error.invalidExp': `Invalid export format`,
  'notification.info.updating': `Updating...`,
  'notification.info.saving': `Saving...`,
  'notification.info.wait': `Please wait...`,
  'notification.warn.title': `Warning!`,
  'notification.warn.interrupted': `Process interrupted`,
  'notification.warn.interrupted.info': `The process was interrupted, please wait a moment and try again.`,
  'notification.warn.loadDs': `Please select and load the dataset you wish to update, or save a new one.`,
  'notification.error.title': `Error!`,
  'notification.error.saving': `An error ocurred while saving your data`,
  'notification.error.updating': `An error ocurred while updating your data`,
  'notification.error.saving.vehA': `An error ocurred while saving vehicle 1 data`,
  'notification.error.updating.vehA': `An error ocurred while updating vehicle 1 data`,
  'notification.error.saving.vehB': `An error ocurred while saving vehicle 2 data`,
  'notification.error.updating.vehB': `An error ocurred while updating vehicle 2 data`,
  'notification.info.success': `Success!`,
  'notification.info.successSaving': `Your data was saved successfuly`,
  'notification.info.successUpdating': `Your data was updated successfuly`,
  'notification.warn.login': `Please login first`,
  'notification.error.loadDs': `Please select a Dataset to load!`,
  'notification.warn.fixSave': `please fix the highlighted issues in the standard units before saving`,
  'notification.warn.fixUpd': `please fix the highlighted issues in the standard units before updating`,
  'notification.warn.fixCalc': `please fix the highlighted issues in the standard units before calculating`,
  'notification.warn.fixCalc2': `please fix the highlighted issues before calculating`,
  'notification.warn.fixCalcUsd': `please fix the highlighted issues in the Standard Units section before Converting to USD`,
  'notification.info.loadDs': `Load the dataset?`,
  'notification.info.loadDs.confirm': `Loading the dataset will overwrite the currently entered data in the form. Do you want to proceed?`,
  'notification.error.invalidDs': `Invalid dataset selected!`,
  'notification.error.fixData.save': `please fix the highlighted issues in the vehicle data section before saving`,
  'notification.error.fixData.update': `please fix the highlighted issues in the vehicle data section before updating`,

  // Form errors
  'forms.err.dsName': `Please set a name for your dataset`,
  'forms.err.selCountry': `Please select a country`,
  'forms.err.required': `Required`,
  'forms.warn.fix': `please fix the highlighted issues before proceeding`,
  'forms.err.zero': `value can't be zero`,
  'forms.err.vehType': `Please select a vehicle type`,
  'forms.err.fuelType': `Please select the type of fuel`,
  'forms.err.vehDescription': `Please enter a vehicle description`,

  // Extras
  'extras.country': `Country`,
  'extras.created': `Created On`,
  'extras.modified': `Last Modified`,
  'extras.delete': `Delete`,
  'extras.actions': `Actions`,
  'extras.datasets': `Datasets`,
  'extras.ovr': `Owning vs Renting`,
  'extras.profile': `Profile`,
  'extras.bought': `bought`,
  'extras.buying': `buying`,
  'extras.rented': `rented`,
  'extras.renting': `renting`,
  'extras.ownSummary': `If you {cheaperOpt} a {vehicleDescription} instead of {expOption} one, you would save {currency} {annualSavings} per year (or {currency} {distanceSavings} per {distanceUnit} driven) over a period of {period} years.`,
  'extras.optSelDistUnit': `-- Select Distance Unit--`,
  'extras.optSelFuelUnit': `-- Select Fuel Unit--`,
  'extras.optSelDispMeth': `-- Select Disposal Method--`
  // 'extras.country': `Country`,
  // 'extras.country': `Country`,
  // 'extras.country': `Country`,
  // 'extras.country': `Country`,
  // 'extras.country': `Country`,
  // 'extras.country': `Country`,
  // 'extras.country': `Country`,
  // 'extras.country': `Country`,
  // 'extras.country': `Country`,
};

// const formatted = {};

// console.log('localeData:', localeData);

// Object.entries(messages).forEach(([key, value]) => {
//   const keys = key.split('.');
//   let current = locale;
//   for (let i = 0; i < keys.length; i++) {
//     const k = keys[i];
//     if (!current[k]) {
//       current[k] = i === keys.length - 1 ? value : {};
//     }
//     current = current[k];
//   }
// });

// console.log(formatted);

export default {
  locale: `en`, // en-US
  ConfigProvider,
  localeData,
  messages
};
